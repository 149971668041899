<template>
    <main></main>
</template>

<script>
import { nStore, nMutations } from '@/store';
export default {
    name: 'BaseNavigation',
    methods: {
        isDashboardOpen() {
            return nStore.isDashboardOpen;
        },
        isBookOpen() {
            return nStore.isBookOpen;
        },
        isAuthorOpen() {
            return nStore.isAuthorOpen;
        },
        isSectionOpen() {
            return nStore.isSectionOpen;
        },
        isNoteOpen() {
            return nStore.isNoteOpen;
        },
        isApplicationAuthOpen() {
            return nStore.isApplicationAuthOpen;
        },
        setDashboardOpen() {
            nMutations.setDashboardOpen(true);
            nMutations.setBookOpen(false);
            nMutations.setAuthorOpen(false);
            nMutations.setSectionOpen(false);
            nMutations.setNoteOpen(false);
            nMutations.setApplicationAuthOpen(false);
            this.$root.$emit('bv::hide::tooltip');
        },
        setBookOpen() {
            nMutations.setBookOpen(true);
            nMutations.setDashboardOpen(false);
            nMutations.setAuthorOpen(false);
            nMutations.setSectionOpen(false);
            nMutations.setNoteOpen(false);
            nMutations.setApplicationAuthOpen(false);
            this.$root.$emit('bv::hide::tooltip');
        },
        setSectionOpen() {
            nMutations.setSectionOpen(true);
            nMutations.setDashboardOpen(false);
            nMutations.setAuthorOpen(false);
            nMutations.setBookOpen(false);
            nMutations.setNoteOpen(false);
            nMutations.setApplicationAuthOpen(false);
            this.$root.$emit('bv::hide::popover');
        },
        setAuthorOpen() {
            nMutations.setAuthorOpen(true);
            nMutations.setDashboardOpen(false);
            nMutations.setSectionOpen(false);
            nMutations.setBookOpen(false);
            nMutations.setNoteOpen(false);
            nMutations.setApplicationAuthOpen(false);
            this.$root.$emit('bv::hide::popover');
        },
        setNoteOpen() {
            nMutations.setNoteOpen(true);
            nMutations.setDashboardOpen(false);
            nMutations.setSectionOpen(false);
            nMutations.setBookOpen(false);
            nMutations.setAuthorOpen(false);
            nMutations.setApplicationAuthOpen(false);
            this.$root.$emit('bv::hide::popover');
        },
        setApplicationAuthOpen() {
            nMutations.setApplicationAuthOpen(true);
            nMutations.setNoteOpen(false);
            nMutations.setDashboardOpen(false);
            nMutations.setSectionOpen(false);
            nMutations.setBookOpen(false);
            nMutations.setAuthorOpen(false);
            this.$root.$emit('bv::hide::popover');
        },
    }
}
</script>
