<template>
	<main>
		<div class="manage-page-container">
			<div class="manage-container">
				<div class="manage-container-header">
					<div class="manage-container-title">Autore</div>
					<b-icon-x
						class="manage-container-close"
						@click="close"
					></b-icon-x>
				</div>
				<div class="manage-container-body">
					<div class="manage-container-cta">
						<span
							v-if="
								getAuthorManageOperation() === operations.INSERT
							"
							>Aggiungi un nuovo autore</span
						>
						<span
							v-if="
								getAuthorManageOperation() === operations.UPDATE
							"
							>Modifica l'autore</span
						>
					</div>
					<div style="clear: both"></div>
					<input
						type="text"
						class="manage-container-input"
						v-model="author"
						autofocus
					/>
				</div>
				<div class="manage-container-footer">
					<div
						class="manage-button manage-button-primary"
						@click="create"
						v-if="getAuthorManageOperation() === operations.INSERT"
					>
						Aggiungi
					</div>
					<div
						class="manage-button manage-button-primary"
						@click="edit"
						v-if="getAuthorManageOperation() === operations.UPDATE"
					>
						Modifica
					</div>
					<div
						class="manage-button manage-button-secondary"
						@click="isConfirmOpen = true"
						v-if="getAuthorManageOperation() === operations.UPDATE"
					>
						Elimina
					</div>
				</div>

				<div
					class="manage-container-confirm"
					v-if="isConfirmOpen === true"
				>
					<div class="manage-container-confirm-inner">
						<div class="manage-container-confirm-cta">
							Sei sicuro?
						</div>
						<div class="manage-container-footer">
							<div
								class="
									manage-button
									manage-confirm-button
									manage-button-primary
								"
								@click="isConfirmOpen = false"
							>
								Chiudi
							</div>
							<div
								class="
									manage-button
									manage-confirm-button
									manage-button-secondary
								"
								@click="remove"
							>
								Elimina
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { nMutations, nStore } from "@/store";
import { author } from "@/services";
import { EVENTS, OPERATIONS } from "@/constants";
import AlertMessage from "@/components/Common/AlertMessage.vue";
export default {
	name: "AuthorManage",
	mixins: [AlertMessage],
	data() {
		return {
			author: this.getAuthorManageAuthorName(),
			operations: OPERATIONS,
			isConfirmOpen: false,
		};
	},
	methods: {
		getAuthorManageAuthorId() {
			return nStore.authorManageAuthor !== null &&
				nStore.authorManageAuthor !== undefined &&
				nStore.authorManageAuthor.id !== null &&
				nStore.authorManageAuthor.id !== undefined
				? nStore.authorManageAuthor.id
				: "";
		},
		getAuthorManageAuthorName() {
			return nStore.authorManageAuthor !== null &&
				nStore.authorManageAuthor !== undefined &&
				nStore.authorManageAuthor.name !== null &&
				nStore.authorManageAuthor.name !== undefined
				? nStore.authorManageAuthor.name
				: "";
		},
		getAuthorManageOperation() {
			return nStore.authorManageOperation;
		},
		close() {
			let data = {
				open: false,
				author: null,
				operation: null,
			};
			nMutations.setAuthorManage(data);
		},
		edit() {
			let data = {
				author: this.author.toUpperCase(),
				id: this.getAuthorManageAuthorId(),
			};
			author.update(data).then((result) => {
				this.showMessage(result.message, result.success === true, true);
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_AUTHOR_LIST);
					window.dispatchEvent(event);
					this.close();
				}
			});
		},
		remove() {
			let data = {
				id: this.getAuthorManageAuthorId(),
			};
			author.delete(data).then((result) => {
				this.showMessage(result.message, result.success === true, true);
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_AUTHOR_LIST);
					window.dispatchEvent(event);
					this.isConfirmOpen = false;
					this.close();
				}
			});
		},
		create() {
			let data = {
				author: this.author.toUpperCase(),
			};
			author.insert(data).then((result) => {
				this.showMessage(result.message, result.success === true, true);
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_AUTHOR_LIST);
					window.dispatchEvent(event);
					this.close();
				}
			});
		},
	},
};
</script>

<style>
.manage-page-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(13, 17, 23, 0.9);
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;
}
.manage-container {
	height: 400px;
	width: 650px;
	border-radius: 15px;
	background-color: #161b22;
}
.manage-container-header {
	font-size: 30px;
	font-weight: bold;
	color: white;
	width: 100%;
	height: 70px;
	line-height: 70px;
	float: left;
	line-height: 70px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.manage-container-body {
	height: 260px;
	width: 100%;
	padding: 10px;
	float: left;
}
.manage-container-footer {
	height: 70px;
	width: 100%;
	float: left;
	border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.manage-container-title {
	float: left;
	padding-left: 20px;
}

.manage-container-cta {
	float: left;
	width: 100%;
	padding-left: 15px;
	font-size: 20px;
	color: rgba(255, 255, 255, 0.7);
	margin-top: 20px;
}

.manage-container-input {
	float: left;
	font-size: 15px;
	margin-left: 12px;
	font-size: 15px;
	border: none;
	outline: none;
	background: transparent;
	width: calc(100% - 30px);
	color: #ffffff;
	margin-top: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	text-transform: uppercase;
	border-radius: 0;
	padding-left: 3px !important;
}

.manage-container-close {
	float: right;
	padding-top: 10px;
	padding-right: 10px;
	width: 60px;
	height: 60px;
	opacity: 0.8;
	cursor: pointer;
}
.manage-container-close:hover {
	opacity: 1;
}
.manage-button {
	width: 150px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-radius: 8px;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(240, 246, 252, 0.2);
	opacity: 0.8;
	margin-top: 15px;
}
.manage-button:hover {
	opacity: 1;
	border: 1px solid rgba(240, 246, 252, 0.5);
}
.manage-button-primary {
	float: right;
	background: #21262d;
	margin-right: 15px;
}
.manage-button-secondary {
	float: left;
	background: red;
	margin-left: 15px;
}
.manage-container-confirm {
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	background-color: rgba(13, 17, 23, 0.9);
}
.manage-container-confirm-inner {
	position: relative;
	height: 150px;
	width: 300px;
	border-radius: 10px;
	background-color: #161b22;
	margin-left: 175px;
	margin-top: 125px;
}
.manage-container-confirm-cta {
	float: left;
	width: 100%;
	padding-left: 15px;
	font-size: 20px;
	font-weight: bold;
	color: rgba(255, 255, 255, 0.7);
	text-align: center;
	height: 80px;
	line-height: 80px;
}
.manage-confirm-button {
	width: 100px;
}
@media screen and (min-width: 768) {
	.manage-container-input {
		font-size: 20px;
	}
}
</style>