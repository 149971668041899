<template>
	<Table
		:fields="fields"
		:items="items"
		buttonCta="Nuova Nota"
		:buttonCb="add"
		:selectCb="edit"
		:isPaginated="true"
		caller="note"
	/>
</template>

<script>
import { note } from "@/services";
import { nMutations } from "@/store";
import { EVENTS, OPERATIONS } from "@/constants";
import Table from "@/components/Common/Table.vue";
export default {
	name: "NoteTable",
	components: { Table },
	data() {
		return {
			fields: [{ key: "note", label: "NOTE", sortable: true }],
			totalRows: 0,
			items: [],
		};
	},
	created() {
		this.registerListener();
		this.init();
	},
	beforeDestroy() {
		this.unregisterListener();
	},
	methods: {
		registerListener() {
			window.addEventListener(EVENTS.UPDATE_NOTE_LIST, this.init);
		},
		unregisterListener() {
			window.removeEventListener(EVENTS.UPDATE_NOTE_LIST, this.init);
		},
		init() {
			note.getList().then((result) => {
				this.items = result.data;
			});
		},
		add() {
			let data = {
				open: true,
				note: null,
				operation: OPERATIONS.INSERT,
			};
			nMutations.setNoteManage(data);
		},
		edit(note) {
			let data = {
				open: true,
				note: {
					name: note.note,
					id: note.id,
				},
				operation: OPERATIONS.UPDATE,
			};
			nMutations.setNoteManage(data);
		},
	},
};
</script>

