<template>
	<main>
		<div class="menu-container">
			<div class="menu-items-container">
				<div
					class="menu-item"
					@click="setDashboardOpen"
					:class="{ 'menu-item-active': isDashboardOpen() === true }"
				>
					<b-icon-house
						v-b-tooltip.hover.right="'Dashboard'"
						class="menu-icon"
					></b-icon-house>
				</div>
				<div
					class="menu-item"
					@click="setBookOpen"
					:class="{ 'menu-item-active': isBookOpen() === true }"
				>
					<b-icon-journal-bookmark
						v-b-tooltip.hover.right="'Libri'"
						class="menu-icon menu-icon-small"
					></b-icon-journal-bookmark>
				</div>
			</div>

			<div class="menu-options-container">
				<div
					class="menu-option"
					:class="{
						'menu-item-active':
							isAuthorOpen() === true ||
							isNoteOpen() === true ||
							isSectionOpen() === true,
					}"
				>
					<b-icon-gear
						class="menu-icon"
						id="menu-settings"
					></b-icon-gear>
				</div>
			</div>
			<b-popover
				target="menu-settings"
				triggers="click blur"
				placement="right"
			>
				<div class="popover-row" @click="setAuthorOpen">Autori</div>
				<div class="popover-row" @click="setSectionOpen">Sezioni</div>
				<div class="popover-row" @click="setNoteOpen">Note</div>
				<div class="popover-row" @click="setApplicationAuthOpen">
					Account
				</div>
			</b-popover>
		</div>
	</main>
</template>

<script>
import BaseNavigation from "@/components/Common/BaseNavigation.vue";
export default {
	name: "Menu",
	mixins: [BaseNavigation],
};
</script>
<style>
.tooltip-inner {
	margin-left: 20px !important;
	border: 0;
	background-color: #21262d !important;
}
.b-popover {
	border-color: transparent !important;
	background-color: transparent !important;
}
.bs-popover-right {
	margin-left: 20px !important;
}
.popover-body {
	background-color: #21262d !important;
	padding: 0 !important;
	color: white !important;
	border-radius: 12px !important;
	min-width: 180px !important;
}
</style>

<style scope>
.menu-container {
	float: left;
	width: 100%;
	height: 65px;
	background: #161b22;
	bottom: 0;
	position: fixed;
	border: 1px solid #0d1117;
}
.menu-items-container {
	height: 100%;
	width: calc(100%);
}
.menu-item {
	height: calc(100% - 2px);
	width: calc(100% / 3);
	float: left;
	border-bottom: 2px solid transparent;
	color: rgba(255, 255, 255, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.menu-item-active {
	color: rgba(255, 255, 255, 1);
	border-bottom: 2px solid white;
}
.menu-options-container {
	width: calc(100% / 3);
	height: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.menu-option {
	width: 100%;
	height: calc(100% - 2px);
	float: left;
	color: rgba(255, 255, 255, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
}
.menu-icon {
	width: 25px;
	height: 25px;
	opacity: 0.7;
	cursor: pointer;
}
.menu-icon-small {
	width: 22px;
	height: 22px;
}
.menu-icon:hover {
	opacity: 1;
}
.menu-icon-active {
	opacity: 1;
}
.popover-row {
	width: 100%;
	height: 40px;
	line-height: 25px;
	text-align: center;
	font-size: 15px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 7px;
	padding-bottom: 7px;
	cursor: pointer;
}
.popover-row:last-child {
	border: none;
}
.menu-option > svg {
	outline: none;
}
#menu-settings {
	margin-top: 0;
}
@media screen and (min-width: 768px) and (orientation: portrait) {
	.menu-container {
		height: 80px;
	}
	.menu-icon {
		width: 30px;
		height: 30px;
		margin-top: -5px;
	}
	#menu-settings {
		margin-top: -10px;
	}
}
@media screen and (min-width: 1024px) {
	.menu-container {
		width: 60px;
		height: 100vh;
		position: relative;
		border: none;
	}
	.menu-items-container {
		width: 100%;
		height: calc(60px * 2);
	}
	.menu-item {
		width: calc(100% - 2px);
		height: 60px;
		border-bottom: 0;
		border-left: 2px solid transparent;
	}
	.menu-item:hover {
		color: rgba(255, 255, 255, 1);
	}
	.menu-item-active {
		border-left: 2px solid white;
	}
	.menu-options-container {
		width: 100%;
		height: calc(60px * 1);
		position: absolute;
		bottom: 0;
		display: block;
	}
	.menu-option {
		width: 60px;
		height: 60px;
		border-bottom: 0;
	}
	.menu-icon {
		width: 34px;
		height: 34px;
		margin-top: 0;
	}
	.menu-small {
		width: 34px;
		height: 34px;
	}
	.menu-icon:hover {
		opacity: 1;
	}
	.menu-icon-active {
		opacity: 1;
	}
	#menu-settings {
		margin-top: 0;
	}
}
</style>
