<template>
	<main>
		<div class="manage-page-container">
			<div class="manage-version-app">
				<p>Client version: {{ clientVersion }}</p>
				<p>Server version: {{ serverVersion }}</p>
			</div>
			<div class="manage-container">
				<div class="manage-container-header">
					<div class="manage-container-title">Account</div>
				</div>
				<div class="manage-container-body">
					<div class="manage-container-cta">
						<span>Auth Keys</span>
					</div>
					<div style="clear: both"></div>
					<input
						type="text"
						class="manage-container-input"
						placeholder="UID"
						v-model="user"
					/>
					<input
						type="text"
						class="manage-container-input"
						placeholder="API KEY"
						v-model="apiKey"
					/>
				</div>

				<div class="manage-container-footer">
					<div
						class="manage-button manage-button-secondary"
						@click="save"
					>
						Salva
					</div>
					<div
						class="manage-button manage-button-primary"
						:class="{
							disabled: isApplicationInitialized === false,
						}"
						@click="close"
					>
						Chiudi
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import AlertMessage from "@/components/Common/AlertMessage.vue";
import BaseNavigation from "@/components/Common/BaseNavigation.vue";
import { EVENTS } from "@/constants";
import { pGetters, pMutations } from "@/store";
import { application } from "@/services";
export default {
	name: "ApplicationAuth",
	mixins: [AlertMessage, BaseNavigation],
	data() {
		return {
			user: null,
			apiKey: null,
			isApplicationInitialized: false,
			clientVersion: application.clientVersion,
			serverVersion: application.serverVersion,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.user = pGetters.getUserId();
			this.apiKey = pGetters.getApiKey();
			this.isApplicationInitialized = application.isInitialized;
		},
		save() {
			pMutations.setUserId(this.user);
			pMutations.setApiKey(this.apiKey);
			application.init().then((result) => {
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_ACCOUNT_KEY);
					window.dispatchEvent(event);
				} else {
					this.showMessage(result.message, false, true);
				}
			});
		},
		close() {
			if (this.isApplicationInitialized === true) {
				this.setDashboardOpen();
			}
		},
	},
};
</script>

<style scoped>
.manage-version-app {
	position: absolute;
	top: 20px;
	left: 20px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 13px;
	text-align: left;
	font-weight: bold;
	width: 150px;
}
.manage-version-app > p {
	margin: 0;
}
.manage-container-input {
	text-transform: none;
}
.manage-button-primary {
	float: left;
	margin-left: 15px;
}
.manage-button-secondary {
	float: right;
	margin-right: 15px;
}
.disabled {
	opacity: 0.3;
	cursor: auto;
}
.disabled:hover {
	border: 1px solid rgba(240, 246, 252, 0.2);
}
</style>
