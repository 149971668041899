<template>
	<Table
		:fields="fields"
		:items="items"
		buttonCta="Nuovo Libro"
		:buttonCb="add"
		:selectCb="edit"
		:isPaginated="true"
		caller="book"
	/>
</template>

<script>
import { book } from "@/services";
import { nMutations } from "@/store";
import { EVENTS, OPERATIONS } from "@/constants";
import Table from "@/components/Common/Table.vue";
export default {
	name: "BookTable",
	components: { Table },
	data() {
		return {
			fields: [
				{ key: "title", label: "TITOLO", sortable: true },
				{ key: "author", label: "AUTORE", sortable: true },
				{ key: "section", label: "SEZIONE", sortable: true },
				{
					key: "date",
					label: "DATA",
					sortable: true,
					sortByFormatted: true,
					formatter: (value, key, item) => {
						return parseInt(item.timestamp);
					},
				},
				{ key: "rank", label: "VOTO", sortable: true },
				{ key: "note", label: "NOTE", sortable: true },
				{ key: "format", label: "FORMATO", sortable: true },
			],
			items: [],
		};
	},
	created() {
		this.registerListener();
		this.init();
	},
	beforeDestroy() {
		this.unregisterListener();
	},
	methods: {
		registerListener() {
			window.addEventListener(EVENTS.UPDATE_BOOK_LIST, this.init);
		},
		unregisterListener() {
			window.removeEventListener(EVENTS.UPDATE_BOOK_LIST, this.init);
		},
		init() {
			book.getList().then((result) => {
				this.items = result.data;
			});
		},
		add() {
			let data = {
				open: true,
				book: {
					id: null,
					title: "",
					author: "",
					start: new Date().toISOString().split("T")[0],
					note: "",
					section: "",
					rank: parseFloat(3) * 2 - 1,
					format: "DIGITALE",
				},
				operation: OPERATIONS.INSERT,
			};
			nMutations.setBookManage(data);
		},
		edit(book) {
			let data = {
				open: true,
				book: {
					id: book.id,
					title: book.title,
					author: book.author,
					start: book.start,
					note: book.note,
					section: book.section,
					rank: parseFloat(book.rank) * 2 - 1,
					format: book.format,
				},
				operation: OPERATIONS.UPDATE,
			};
			nMutations.setBookManage(data);
		},
	},
};
</script>

