<template>
	<main>
		<div class="page-main book-main">
			<BookTable />
		</div>
		<BookManage v-if="isBookManageOpen() === true" />
	</main>
</template>

<script>
import { nStore } from "@/store";
import BookTable from "@/components/Book/BookTable.vue";
import BookManage from "@/components/Book/BookManage.vue";
export default {
	name: "BookMain",
	components: {
		BookTable,
		BookManage,
	},
	methods: {
		isBookManageOpen() {
			return nStore.isBookManageOpen;
		},
	},
};
</script>
