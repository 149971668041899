<template>
    <main>
        <div class="manage-page-container">
            <div class="manage-container">
                <div class="manage-container-header">
                    <div class="manage-container-title">Sezione</div>
                    <b-icon-x class="manage-container-close" @click="close"></b-icon-x>
                </div>
                <div class="manage-container-body">
                    <div class="manage-container-cta">
                        <span v-if="getSectionManageOperation() === operations.INSERT">Aggiungi una nuova sezione</span>
                        <span v-if="getSectionManageOperation() === operations.UPDATE">Modifica la sezione</span>
                    </div>
                    <div style="clear:both"></div>
                    <input type="text" class="manage-container-input" v-model="section" autofocus/>
                </div>

                <div class="manage-container-footer">
                    <div class="manage-button manage-button-primary"  @click="create" v-if="getSectionManageOperation() === operations.INSERT">Aggiungi</div> 
                    <div class="manage-button manage-button-primary" @click="edit" v-if="getSectionManageOperation() === operations.UPDATE">Modifica</div>
                    <div class="manage-button manage-button-secondary" @click="isConfirmOpen = true" v-if="getSectionManageOperation() === operations.UPDATE">Elimina</div>
                </div>

                <div class="manage-container-confirm" v-if="isConfirmOpen === true">
                    <div class="manage-container-confirm-inner">
                        <div class="manage-container-confirm-cta">
                            Sei sicuro?
                        </div>
                        <div class="manage-container-footer">
                            <div class="manage-button manage-confirm-button manage-button-primary" @click="isConfirmOpen = false" >Chiudi</div> 
                            <div class="manage-button manage-confirm-button manage-button-secondary" @click="remove">Elimina</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { nMutations, nStore } from '@/store';
import { section } from '@/services';
import { EVENTS, OPERATIONS } from '@/constants';
import AlertMessage from '@/components/Common/AlertMessage.vue';
export default {
    name: 'SectionManage',
    mixins: [AlertMessage],
    data() {
        return {
            section: this.getSectionManageSectionName(),
            operations: OPERATIONS,
            isConfirmOpen: false
        }
    },
    methods: {
        getSectionManageSectionId() {
            return nStore.sectionManageSection !== null && nStore.sectionManageSection !== undefined && nStore.sectionManageSection.id !== null && nStore.sectionManageSection.id !== undefined ? nStore.sectionManageSection.id : '';
        },
        getSectionManageSectionName() {
            return nStore.sectionManageSection !== null && nStore.sectionManageSection !== undefined && nStore.sectionManageSection.name !== null && nStore.sectionManageSection.name !== undefined ? nStore.sectionManageSection.name : '';
        },
        getSectionManageOperation() {
            return nStore.sectionManageOperation;
        },
        close() {
            let data = {
                open: false,
                section: null,
                operation: null
            };
            nMutations.setSectionManage(data);
        },
        edit() {
            let data = {
                section: this.section.toUpperCase(),
                id: this.getSectionManageSectionId()
            };
            section.update(data).then(result => {
                this.showMessage(result.message, result.success === true, true);
                if (result.success === true) {
                    let event = new Event(EVENTS.UPDATE_SECTION_LIST);
                    window.dispatchEvent(event);
                    this.close();
                }
            });
        },
        remove() {
            let data = {
                id: this.getSectionManageSectionId()
            };
            section.delete(data).then(result => {
                this.showMessage(result.message, result.success === true, true);
                if (result.success === true) {
                    let event = new Event(EVENTS.UPDATE_SECTION_LIST);
                    window.dispatchEvent(event);
                    this.isConfirmOpen = false;
                    this.close();
                }
            });
        },
        create() {
            let data = {
                section: this.section.toUpperCase()
            };
            section.insert(data).then(result => {
                this.showMessage(result.message, result.success === true, true);
                if (result.success === true) {
                    let event = new Event(EVENTS.UPDATE_SECTION_LIST);
                    window.dispatchEvent(event);
                    this.close();
                }
            });
        }
    }
}
</script>
