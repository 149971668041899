<template>
	<main>
		<div class="main-page-container">
			<NoteMain v-if="isNoteOpen() === true" />
			<SectionMain v-if="isSectionOpen() === true" />
			<AuthorMain v-if="isAuthorOpen() === true" />
			<BookMain v-if="isBookOpen() === true" />
			<DashboardMain v-if="isDashboardOpen() === true" />
			<ApplicationAuth v-if="isApplicationAuthOpen() === true" />
			<AlertMessage />
		</div>
	</main>
</template>

<script>
import BaseNavigation from "@/components/Common/BaseNavigation.vue";
import DashboardMain from "@/components/Dashboard/DashboardMain.vue";
import BookMain from "@/components/Book/BookMain.vue";
import AuthorMain from "@/components/Author/AuthorMain.vue";
import SectionMain from "@/components/Section/SectionMain.vue";
import NoteMain from "@/components/Note/NoteMain.vue";
import ApplicationAuth from "@/components/Application/ApplicationAuth.vue";
import AlertMessage from "@/components/Common/AlertMessage.vue";
export default {
	name: "MainPage",
	mixins: [BaseNavigation],
	components: {
		AuthorMain,
		DashboardMain,
		BookMain,
		SectionMain,
		NoteMain,
		AlertMessage,
		ApplicationAuth,
	},
	methods: {
		update() {
			window.location.reload(true);
		},
	},
};
</script>

<style scoped>
.main-page-container {
	width: calc(100%);
	float: left;
	height: calc(100vh - 65px);
	background-color: #0d1117;
	color: #c9d1d9;
	overflow: hidden;
}
@media screen and (min-width: 1024px) {
	.main-page-container {
		width: calc(100% - 60px);
		height: 100vh;
	}
}
@media screen and (min-width: 1024px) {
	.main-page-container {
		width: calc(100% - 60px);
		height: 100vh;
	}
}
</style>

