<template>
	<main>
		<div class="manage-page-container">
			<div class="manage-container">
				<div class="manage-container-header">
					<div class="manage-container-title">Scheda</div>
					<b-icon-x
						class="manage-container-close"
						@click="close"
					></b-icon-x>
				</div>
				<div class="manage-container-body">
					<input
						type="text"
						class="manage-container-input"
						placeholder="Titolo"
						v-model="book.title"
					/>

					<b-form-input
						class="
							manage-container-input manage-container-half-input
						"
						list="authorList"
						v-model="book.author"
						placeholder="Autore"
					></b-form-input>
					<datalist id="authorList">
						<option
							v-for="(authorOption, index) in authorOptions"
							v-bind:key="index"
						>
							{{ authorOption }}
						</option>
					</datalist>

					<b-form-input
						class="
							manage-container-input manage-container-half-input
							float-right
						"
						list="sectionList"
						v-model="book.section"
						placeholder="Sezione"
					></b-form-input>
					<datalist id="sectionList">
						<option
							v-for="(sectionOption, index) in sectionOptions"
							v-bind:key="index"
						>
							{{ sectionOption }}
						</option>
					</datalist>

					<b-form-datepicker
						:dark="true"
						v-model="book.start"
						:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							weekday: 'short',
						}"
						class="
							manage-container-input manage-container-half-input
						"
					></b-form-datepicker>
					<b-form-rating
						v-model="book.rank"
						:stars="9"
						class="
							manage-container-input manage-container-half-input
							float-right
						"
					></b-form-rating>

					<b-form-input
						class="
							manage-container-input manage-container-half-input
						"
						list="noteList"
						v-model="book.note"
						placeholder="Note"
					></b-form-input>
					<datalist id="noteList">
						<option
							v-for="(noteOption, index) in noteOptions"
							v-bind:key="index"
						>
							{{ noteOption }}
						</option>
					</datalist>
					<b-form-select
						v-if="isMobile === false"
						class="
							manage-container-input manage-container-half-input
							float-right
						"
						v-model="book.format"
						:options="formatOptions"
					></b-form-select>
					<b-form-input
						v-if="isMobile === true"
						class="
							manage-container-input manage-container-half-input
							float-right
						"
						list="formatList"
						v-model="book.format"
						placeholder="Note"
					></b-form-input>
					<datalist id="formatList" v-if="isMobile === true">
						<option>DIGITALE</option>
						<option>CARTACEO</option>
					</datalist>
				</div>
				<div class="manage-container-footer">
					<div
						class="manage-button manage-button-primary"
						@click="create"
						v-if="getBookManageOperation() === operations.INSERT"
					>
						Aggiungi
					</div>
					<div
						class="manage-button manage-button-primary"
						@click="edit"
						v-if="getBookManageOperation() === operations.UPDATE"
					>
						Modifica
					</div>
					<div
						class="manage-button manage-button-secondary"
						@click="isConfirmOpen = true"
						v-if="getBookManageOperation() === operations.UPDATE"
					>
						Elimina
					</div>
				</div>

				<div
					class="manage-container-confirm"
					v-if="isConfirmOpen === true"
				>
					<div class="manage-container-confirm-inner">
						<div class="manage-container-confirm-cta">
							Sei sicuro?
						</div>
						<div class="manage-container-footer">
							<div
								class="
									manage-button
									manage-confirm-button
									manage-button-primary
								"
								@click="isConfirmOpen = false"
							>
								Chiudi
							</div>
							<div
								class="
									manage-button
									manage-confirm-button
									manage-button-secondary
								"
								@click="remove"
							>
								Elimina
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { nMutations, nStore } from "@/store";
import { book, note, section, author } from "@/services";
import { OPERATIONS, EVENTS } from "@/constants";
import AlertMessage from "@/components/Common/AlertMessage.vue";
export default {
	name: "BookManage",
	mixins: [AlertMessage],
	data() {
		return {
			book: this.getBookManageBook(),
			operations: OPERATIONS,
			isConfirmOpen: false,
			formatOptions: [
				{ value: "DIGITALE", text: "DIGITALE" },
				{ value: "CARTACEO", text: "CARTACEO" },
			],
			noteOptions: [],
			authorOptions: [],
			sectionOptions: [],
			isMobile: innerWidth < 1024,
		};
	},
	mounted() {
		let notes = [];
		let sections = [];
		let authors = [];
		note.getList().then((result) => {
			if (result.success === true) {
				result.data.forEach((note) => {
					notes.push(note.note);
				});
				this.noteOptions = notes;
			}
		});
		section.getList().then((result) => {
			if (result.success === true) {
				result.data.forEach((section) => {
					sections.push(section.section);
				});
				this.sectionOptions = sections;
			}
		});
		author.getList().then((result) => {
			if (result.success === true) {
				result.data.forEach((author) => {
					authors.push(author.author);
				});
				this.authorOptions = authors;
			}
		});
	},
	methods: {
		getBookManageBook() {
			return nStore.bookManageBook !== null &&
				nStore.bookManageBook !== undefined
				? nStore.bookManageBook
				: {};
		},
		getBookManageOperation() {
			return nStore.bookManageOperation;
		},
		close() {
			let data = {
				open: false,
				book: null,
				operation: null,
			};
			nMutations.setBookManage(data);
		},
		edit() {
			let data = {
				note:
					this.book.note !== null &&
					this.book.note !== undefined &&
					this.book.note.length > 0
						? this.book.note.toUpperCase()
						: null,
				section: this.book.section.toUpperCase(),
				author: this.book.author.toUpperCase(),
				title: this.book.title.toUpperCase(),
				date: this.book.start,
				format:
					this.book.format === "DIGITALE" ||
					this.book.format === "CARTACEO"
						? this.book.format
						: "DIGITALE",
				id: this.book.id,
				rank: this.book.rank / 2 + 0.5,
			};
			book.update(data).then((result) => {
				this.showMessage(result.message, result.success === true, true);
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_BOOK_LIST);
					window.dispatchEvent(event);
					this.close();
				}
			});
		},
		remove() {
			let data = {
				id: this.book.id,
			};
			book.delete(data).then((result) => {
				this.showMessage(result.message, result.success === true, true);
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_BOOK_LIST);
					window.dispatchEvent(event);
					this.isConfirmOpen = false;
					this.close();
				}
			});
		},
		create() {
			let data = {
				note:
					this.book.note !== null &&
					this.book.note !== undefined &&
					this.book.note.length > 0
						? this.book.note.toUpperCase()
						: null,
				section: this.book.section.toUpperCase(),
				author: this.book.author.toUpperCase(),
				title: this.book.title.toUpperCase(),
				date: this.book.start,
				format:
					this.book.format === "DIGITALE" ||
					this.book.format === "CARTACEO"
						? this.book.format
						: "DIGITALE",
				rank: this.book.rank / 2 + 0.5,
			};
			book.insert(data).then((result) => {
				this.showMessage(result.message, result.success === true, true);
				if (result.success === true) {
					let event = new Event(EVENTS.UPDATE_BOOK_LIST);
					window.dispatchEvent(event);
					this.close();
				}
			});
		},
	},
};
</script>

<style>
.manage-container-input > .b-rating-star {
	margin-bottom: 3px !important;
	margin-top: -3px !important;
}
.manage-container-input > .b-rating-star:nth-child(odd) {
	transform: scale(1.3);
}

.manage-container-input > .b-rating-star:nth-child(even) {
	transform: scale(0.95);
}

.b-form-btn-label-control.form-control {
	border-radius: 0 !important;
}
.dropdown-menu,
.bg-dark {
	background-color: #161b22 !important;
}
.b-calendar-header {
	display: none;
}
.b-calendar-grid-help {
	display: none;
}
.b-calendar .b-calendar-grid {
	background-color: #161b22 !important;
	outline: none !important;
	color: white !important;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
	color: white !important;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn:hover {
	border-radius: 5px !important;
	background-color: #21262d !important;
}
.btn-primary.active {
	border-radius: 10px !important;
	background-color: #21262d !important;
	font-weight: bold;
}
.form-control {
	background-color: transparent !important;
	border: none !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
	color: white !important;
	padding: 0 !important;
	height: 25px !important;
}
.form-control:focus {
	border-color: transparent !important;
	box-shadow: 0, 0, 0, 0 !important;
	box-shadow: none !important;
}
.b-rating-icon {
	margin-bottom: 2px !important;
	margin-top: -2px !important;
}
.b-form-btn-label-control.form-control > .form-control {
	border: none !important;
	padding: 0 !important;
}
.b-form-btn-label-control.form-control > .btn {
	color: white !important;
	padding-left: 3px !important;
	padding-right: 5px !important;
	margin-top: -8px !important;
}
.b-calendar-grid,
.b-calendar-inner {
	min-height: 250px !important;
}
@media screen and (min-width: 768) {
	.manage-container-input > .b-rating-star:nth-child(odd) {
		transform: scale(1.6);
	}

	.manage-container-input > .b-rating-star:nth-child(even) {
		transform: scale(0.9);
	}
}
</style>
<style scoped>
.manage-container-input {
	border-radius: 0;
	margin-top: 27px;
	font-size: 13px;
	height: 25px;
	padding-left: 3px !important;
}
.manage-container-half-input {
	width: calc(50% - 30px);
}
.float-right {
	float: right;
	margin-right: 15px;
}
@media screen and (min-width: 768) {
	.manage-container-input {
		font-size: 15px;
	}
}
</style>