import Vue from "vue";

export const pStore = Vue.observable({
    userId: null,
    apiKey: null,
    create() {
      if (pGetters.getUserId() !== null && pGetters.getUserId !== undefined && pGetters.getUserId().length > 0) {
        pStore.userId = pGetters.getUserId();
      } else {
        pMutations.setUserId('');
      }
      if (pGetters.getApiKey() !== null && pGetters.getApiKey !== undefined && pGetters.getApiKey().length > 0) {
        pStore.apiKey = pGetters.getApiKey();
      } else {
        pMutations.setApiKey('');
      }
    }
});

export const pKeys = {
  USER_ID: 'userId',
  API_KEY: 'apiKey'
};

export const pMutations = {
  setUserId(id) {
    window.localStorage.setItem(pKeys.USER_ID, id);
    pStore.userId = id;
  },
  setApiKey(apiKey) {
    window.localStorage.setItem(pKeys.API_KEY, apiKey);
    pStore.apiKey = apiKey;
  }
};

export const pGetters = {
  getApiKey() {
    return window.localStorage.getItem(pKeys.API_KEY);
  },
  getUserId() {
    return window.localStorage.getItem(pKeys.USER_ID);
  }
};

export const aStore = Vue.observable({
  isAlertVisible: false,
  isAlertSuccess: false,
  alertMessage: '',
  isAlertLogVisible: false,
  alertLogMessage: ''
});

export const aMutations = {
  setAlertVisible(visible) {
    aStore.isAlertVisible = visible;
  },
  setAlertSuccess(success) {
    aStore.isAlertSuccess = success;
  },
  setAlertMessage(message) {
    aStore.alertMessage = message;
  },
  setAlertLogVisible(visible) {
    aStore.isAlertLogVisible = visible;
  },
  setAlertLogMessage(message) {
    aStore.alertLogMessage = message;
  }
};

export const nStore = Vue.observable({
  isDashboardOpen: false,
  isBookOpen: false,
  isAuthorOpen: false,
  isSectionOpen: false,
  isNoteOpen: false,
  isAuthorManageOpen: false,
  isApplicationAuthOpen: false,
  authorManageAuthor: {},
  authorManageOperation: null,
  isNoteManageOpen: false,
  noteManageNote: {},
  noteManageOperation: null,
  isSectionManageOpen: false,
  sectionManageSection: {},
  sectionManageOperation: null,
  isBookManageOpen: false,
  bookManageSection: {},
  bookManageOperation: null
});

export const nMutations = {
  setDashboardOpen(open) {
    nStore.isDashboardOpen = open;
  },
  setBookOpen(open) {
    nStore.isBookOpen = open;
  },
  setAuthorOpen(open) {
    nStore.isAuthorOpen = open;
  },
  setSectionOpen(open) {
    nStore.isSectionOpen = open;
  },
  setNoteOpen(open) {
    nStore.isNoteOpen = open;
  },
  setApplicationAuthOpen(open) {
    nStore.isApplicationAuthOpen = open;
  },
  setAuthorManage(data) {
    nStore.isAuthorManageOpen = data.open,
    nStore.authorManageAuthor = data.author,
    nStore.authorManageOperation = data.operation
  },
  setNoteManage(data) {
    nStore.isNoteManageOpen = data.open,
    nStore.noteManageNote = data.note,
    nStore.noteManageOperation = data.operation
  },
  setSectionManage(data) {
    nStore.isSectionManageOpen = data.open,
    nStore.sectionManageSection = data.section,
    nStore.sectionManageOperation = data.operation
  },
  setBookManage(data) {
    nStore.isBookManageOpen = data.open,
    nStore.bookManageBook = data.book,
    nStore.bookManageOperation = data.operation
  }
}