<template>
	<div class="calendar-main-container">
		<v-calendar
			ref="calendar"
			class="custom-calendar"
			:masks="masks"
			:attributes="attributes"
			is-dark
			is-expanded
		>
			<template v-slot:day-content="{ day, attributes }">
				<div class="flex flex-col h-full z-10 overflow-hidden">
					<span>{{ day.day }}</span>
					<div class="flex-grow overflow-y-auto overflow-x-auto">
						<div
							v-for="attr in attributes"
							:key="attr.key"
							:class="attr.customData.class"
						>
							<div v-if="isPhone === false">
								{{ attr.customData.title }}
							</div>
							<div
								v-if="isPhone === true"
								class="calendar-book-read-dot"
							></div>
						</div>
					</div>
				</div>
			</template>
		</v-calendar>
	</div>
</template>

<script>
export default {
	name: "DashboardCalendar",
	props: {
		data: Array,
	},
	data() {
		return {
			masks: {
				weekdays: "WWW",
			},
			attributes: [],
			isPhone:
				window.orientation === 90 || window.orientation === -90
					? window.innerHeight < 768
					: window.innerWidth < 768,
		};
	},
	mounted() {
		let attributes = [];
		this.data.forEach((book) => {
			let time = book.timestamp + "000";
			let attribute = {
				key: book.id,
				customData: {
					title: book.title.toLowerCase(),
					class: "calendar-book-text",
				},
				dates: new Date(parseInt(time)),
			};
			attributes.push(attribute);
		});
		this.attributes = attributes;
		if (this.data.length > 0) {
			let lastBook = this.data[0];
			let lastBookYear = parseInt(lastBook.start.split("-")[0]);
			let lastBookMonth = parseInt(lastBook.start.split("-")[1]);
			this.$refs.calendar.move({
				month: lastBookMonth,
				year: lastBookYear,
			});
		}
	},
	methods: {
		test(data) {
			console.log(data);
		},
	},
};
</script>

<style>
.vc-day {
	border: 1px solid rgba(240, 246, 252, 0.03) !important;
	padding: 5px;
}
.vc-day > div > span {
	font-weight: bold;
}
.vc-weekday {
	color: #e6dcdc !important;
}
.vc-svg-icon path {
	fill: rgba(255, 255, 255, 0.7) !important;
}
</style>

<style scoped>
.calendar-book-read-dot {
	width: 9px;
	height: 9px;
	float: left;
	background-color: #0d1117;
	border: 1px solid white;
	border-radius: 50%;
	margin-right: 2px;
	margin-top: 2px;
}
.calendar-book-text {
	font-size: 13px;
	color: rgba(255, 255, 255, 1) !important;
	text-transform: capitalize;
}
.calendar-book-text:hover {
	color: rgba(255, 255, 255, 1);
}
.custom-calendar {
	width: 100%;
	min-width: none;
	background-color: #161b22 !important;
	border: none;
}
</style>

<style scoped>
.calendar-main-container {
	width: 100%;
	float: left;
	justify-content: space-between;
	flex-direction: column;
	font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
@media screen and (min-width: 1024px) {
	.calendar-main-container {
		width: calc(100% - 270px);
		margin-left: 30px;
	}
}
</style>

