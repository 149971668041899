<template>
	<main>
		<div class="page-main dashboard-main">
			<DashboardStatistics
				:data="statistics"
				v-if="statistics !== null"
			/>
			<DashboardCalendar
				:data="statistics.books"
				v-if="statistics !== null"
			/>
		</div>
	</main>
</template>

<script>
import { dashboard } from "@/services";
import DashboardStatistics from "@/components/Dashboard/DashboardStatistics.vue";
import DashboardCalendar from "@/components/Dashboard/DashboardCalendar.vue";
export default {
	name: "DashboardMain",
	components: {
		DashboardStatistics,
		DashboardCalendar,
	},
	data() {
		return {
			statistics: null,
		};
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			dashboard.get().then((result) => {
				if (result.success === true) {
					this.statistics = result.data;
					this.statistics.newYear = new Date().getFullYear();
				}
			});
		},
	},
};
</script>