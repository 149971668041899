<template>
	<main class="full-height">
		<div class="table-option-container">
			<input
				type="text"
				class="table-option-search"
				placeholder="Cerca"
				v-model="filter"
			/>
			<b-icon-x
				@click="filter = null"
				class="icon-search-delete"
			></b-icon-x>
			<div @click="buttonCb" class="table-option-button">
				{{ buttonCta }}
			</div>
		</div>
		<div style="clear: both"></div>
		<b-table
			:dark="true"
			:sticky-header="true"
			:items="items"
			:fields="fields"
			:filter="filter"
			:current-page="currentPage"
			label-sort-asc=""
			label-sort-desc=""
			@row-clicked="onSelect"
			@filtered="onFiltered"
			:per-page="perPage"
		>
			<template #cell(title)="data">
				<b>{{ data.item.title }}</b>
			</template>

			<template #cell(date)="data">
				<b v-if="isMobile === false">{{ data.item.date }}</b>
				<b v-if="isMobile === true">{{ mobileData(data.item.date) }}</b>
			</template>

			<template #cell(author)="data">
				<b v-if="caller === 'book'">{{ data.item.author }}</b>
				<span v-if="caller !== 'book'">{{ data.item.author }}</span>
			</template>

			<template #cell(rank)="data">
				<b-form-rating
					v-model="data.item.rank"
					readonly
				></b-form-rating>
			</template>

			<template #cell(note)="data">
				<span class="portrait-hide">{{ data.item.note }}</span>
			</template>

			<template #cell(format)="data">
				<span class="portrait-hide">{{ data.item.format }}</span>
			</template>
		</b-table>
		<div style="clear: both"></div>
		<b-pagination
			v-if="isPaginated === true"
			v-model="currentPage"
			:per-page="perPage"
			:total-rows="totalRows"
			align="fill"
			size="sm"
			class="my-0"
		></b-pagination>
		<div style="clear: both"></div>
	</main>
</template>

<script>
export default {
	name: "Table",
	props: {
		items: Array,
		fields: Array,
		buttonCta: String,
		buttonCb: Function,
		selectCb: Function,
		isPaginated: Boolean,
		caller: String,
	},
	data() {
		return {
			perPage: 10,
			currentPage: 1,
			filter: null,
			totalRows: 0,
			isMobile: false,
		};
	},
	created() {
		this.registerListener();
	},
	beforeDestroy() {
		this.unregisterListener();
	},
	watch: {
		items() {
			if (this.items !== null && this.items !== undefined) {
				this.setLayout();
			}
		},
	},
	methods: {
		registerListener() {
			window.addEventListener("resize", (e) => this.setLayout(e));
		},
		unregisterListener() {
			window.removeEventListener("resize", (e) => this.setLayout(e));
		},
		mobileData(data) {
			let splitData = data.split("/");
			return (
				splitData[0] + "/" + splitData[1] + "/" + splitData[2].slice(2)
			);
		},
		setLayout() {
			let innerHeight = window.innerHeight;
			let innerWidth = window.innerWidth;
			this.isMobile = innerWidth < 1024;
			let pagedConstant = 85;
			if (
				(window.orientation === 90 || window.orientation === -90) &&
				this.isMobile === true
			) {
				pagedConstant = pagedConstant + 15;
			}
			this.filter = null;
			let perPage = parseInt(innerHeight / pagedConstant);
			console.log(pagedConstant);
			this.totalRows = this.items.length;
			if (this.isPaginated === true) {
				this.currentPage = 1;
				this.perPage = perPage;
			} else {
				this.currentPage = null;
				this.perPage = null;
			}
			this.$forceUpdate();

			this.$nextTick(() => {
				let columnsRow = document.getElementsByTagName("td");
				let columnsHeader = document.getElementsByTagName("th");
				for (let col of columnsRow) {
					if (
						parseInt(col.ariaColIndex) === 6 ||
						parseInt(col.ariaColIndex) === 7
					) {
						col.classList.add("table-hide-cell");
					}
					if (
						parseInt(col.ariaColIndex) === 3 &&
						this.isMobile === true
					) {
						col.classList.add("table-hide-cell");
					}
				}
				for (let col of columnsHeader) {
					if (
						parseInt(col.ariaColIndex) === 6 ||
						parseInt(col.ariaColIndex) === 7
					) {
						col.classList.add("table-hide-cell");
					}
					if (
						parseInt(col.ariaColIndex) === 3 &&
						this.isMobile === true
					) {
						col.classList.add("table-hide-cell");
					}
				}
			});
		},
		onSelect(data) {
			this.selectCb(data);
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			if (this.isPaginated === true) {
				this.currentPage = 1;
			}
		},
	},
};
</script>

<style>
.table > thead {
	height: 60px !important;
	vertical-align: middle !important;
}
.b-table-sticky-header {
	border-radius: 5px !important;
}
.table > :not(caption) > * > * {
	background-color: #161b22 !important;
	vertical-align: middle !important;
	cursor: pointer;
}
td {
	font-size: 12px !important;
}
th {
	font-size: 13px !important;
}
td > .form-control {
	border: none !important;
}
tr:last-child {
	border: none;
}
tr:last-child > td {
	border: none;
}
.b-table-sticky-header {
	max-height: calc(100vh - 190px) !important;
	width: 100%;
	height: 100%;
}
.b-table-sticky-header .table {
	height: 100%;
}
.pagination {
	overflow: hidden;
}
.pagination-sm .page-link {
	background-color: #161b22 !important;
	color: rgba(255, 255, 255, 0.7) !important;
	border-color: 1px solid rgba(255, 255, 255, 1) !important;
}
.page-item.disabled .page-link {
	background-color: rgba(15, 15, 15, 0.5) !important;
	color: rgba(255, 255, 255, 0.7) !important;
	opacity: 0.5;
}
.page-item.active .page-link {
	background-color: #ffffff !important;
	color: black !important;
}
.page-link {
	border: 1px solid rgba(240, 246, 252, 0.2) !important;
	font-size: 15px !important;
	font-weight: bold;
}
.table-hide-cell {
	display: none;
}
.b-rating-icon {
	font-size: 10px;
}
.b-rating .b-rating-star {
	padding: 0 0.1em !important;
}
@media screen and (min-width: 768px) and (orientation: portrait) {
	.b-table-sticky-header {
		max-height: calc(100vh - 205px) !important;
	}
	td {
		font-size: 13px !important;
	}
	th {
		font-size: 15px !important;
	}
	.b-rating .b-rating-star {
		padding: 0 0.25em !important;
	}
	.b-rating-icon {
		font-size: 1rem !important;
	}
}
@media screen and (min-width: 1024px) {
	.table-hide-cell {
		display: table-cell;
	}
	.b-table-sticky-header {
		max-height: calc(100vh - 150px) !important;
	}
	td {
		font-size: 13px !important;
	}
	th {
		font-size: 15px !important;
	}
	.b-rating .b-rating-star {
		padding: 0 0.25em !important;
	}
	.b-rating-icon {
		font-size: 1rem !important;
	}
}
@media screen and (min-width: 1025px) {
	.b-table-sticky-header {
		max-height: calc(100vh - 145px) !important;
	}
}
</style>
<style scoped>
.icon-search-delete {
	height: 30px;
	margin-left: -12px;
	margin-bottom: -5px;
}
.table-option-container {
	width: 100%;
	float: left;
	margin-bottom: 20px;
	float: left;
}
.table-option-search {
	width: 70%;
	max-width: calc(100% - 150px);
	float: left;
	background: transparent;
	color: white;
	outline: none;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.6);
	border-radius: 0;
	height: 30px;
	line-height: 30px;
}
.table-option-button {
	width: 130px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	border-radius: 8px;
	font-size: 14px;
	font-weight: bold;
	float: right;
	cursor: pointer;
	color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(240, 246, 252, 0.2);
	background: #21262d;
	opacity: 0.8;
}
.table-option-button:hover {
	opacity: 1;
	border: 1px solid rgba(240, 246, 252, 0.5);
}
</style>
