<template>
	<main>
		<div class="page-main note-main">
			<NoteTable />
		</div>
		<NoteManage v-if="isNoteManageOpen() === true" />
	</main>
</template>

<script>
import { nStore } from "@/store";
import NoteManage from "@/components/Note/NoteManage.vue";
import NoteTable from "@/components/Note/NoteTable.vue";
export default {
	name: "NoteMain",
	components: {
		NoteTable,
		NoteManage,
	},
	methods: {
		isNoteManageOpen() {
			return nStore.isNoteManageOpen;
		},
	},
};
</script>
