<template>
	<main>
		<div class="alert alert-log"
			 v-if="isAlertLogVisible() === true"
			 @click="dismissLogMessage(0)">
			<p class="title"
			   v-html="getAlertLogMessage()"></p>
		</div>
		<div class="alert"
			 :class="{'alert-success' : isAlertSuccess() === true, 'alert-error' : isAlertSuccess() === false}"
			 v-if="isAlertVisible() === true"
			 @click="dismissMessage(0)">
			<p class="title">{{getAlertMessage()}}</p>
		</div>
	</main>
</template>

<script>
import { aStore, aMutations } from "@/store";
export default {
	name: "AlertMessage",
	data() {
		return {
			timeToClose: null,
		};
	},
	methods: {
		isAlertLogVisible() {
			return aStore.isAlertLogVisible;
		},
		isAlertVisible() {
			return aStore.isAlertVisible;
		},
		isAlertSuccess() {
			return aStore.isAlertSuccess;
		},
		getAlertMessage() {
			return aStore.alertMessage;
		},
		getAlertLogMessage() {
			return aStore.alertLogMessage;
		},
		showMessage(message, success, autoClose, time) {
			aMutations.setAlertSuccess(success);
			aMutations.setAlertMessage(message);
			aMutations.setAlertVisible(true);
			if (success === true || autoClose === true) {
				this.dismissMessage(
					time !== null && time !== undefined ? time : 5000
				);
			}
		},
		showLogMessage(message, time) {
			aMutations.setAlertLogMessage(message);
			aMutations.setAlertLogVisible(true);
			this.dismissLogMessage(
				time !== null && time !== undefined ? time : 10000
			);
		},
		dismissMessage(time) {
			this.timeToClose = setTimeout(function () {
				aMutations.setAlertVisible(false);
				clearTimeout(this.timeToClose);
				this.timeToClose = null;
			}, time);
		},
		dismissLogMessage(time) {
			this.timeToCloseLog = setTimeout(function () {
				aMutations.setAlertLogVisible(false);
				clearTimeout(this.timeToCloseLog);
				this.timeToCloseLog = null;
			}, time);
		},
	},
};
</script>

<style scoped>
.alert {
	min-width: 320px;
	max-width: 450px;
	min-height: 90px;
	color: #ffffff;
	position: absolute;
	border-radius: 20px;
	right: 50px;
	top: 50px;
	cursor: pointer;
	animation: pulse 1s infinite;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
    z-index:10;
	border:none;
}
.title {
	font-size: 18px;
	font-weight: bold;
	padding:0;
	margin:0;
	padding-left: 10px;
	padding-right: 10px;
}
.alert-success {
	background-color: #207567;
}
.alert-error {
	background-color: #77171a;
}
.alert-log {
	background-color: #0e1e2b;
	top: 50px;
	bottom: auto;
	max-width: 1350px;
}
@keyframes pulse {
	from {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(0.95);
		opacity: 0.65;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
</style>

