import {SERVER} from '@/constants';
import {pGetters} from '@/store';
const req = {
    baseUrl() {
        return SERVER.ADDRESS + SERVER.PORT + SERVER.API_ENDPOINT + SERVER.API_VERSION
    },
    apiKey() {
        return pGetters.getApiKey()
    },
    userId() {
        return pGetters.getUserId()
    },
    headers() {
        return new Headers({ 'Authorization': req.apiKey(), 'uid': req.userId(), 'Content-Type': 'application/x-www-form-urlencoded' })
    }
};

export const dashboard = {
    async get() {
        if (application.isInitialized === true) {
            try {
                let response = {};
                let res = await fetch(req.baseUrl() + '/dashboard', {
                    method: 'GET',
                    headers: req.headers()
                });
                let dashboard = await res.json();
                if (res.status === 200) {
                    response.success = true;
                    response.data = dashboard;
                } else {
                    response = dashboard;
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }} else {
            return {success: false}
        }
    }
};

export const author = {
    async getList() {
        if (application.isInitialized === true) {
            try {
                let response = {};
                let res = await fetch(req.baseUrl() + '/authors', {
                    method: 'GET',
                    headers: req.headers()
                });
                let authors = await res.json();
                if (res.status === 200) {
                    response.success = true;
                    response.data = authors;
                } else {
                    response = authors;
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }} else {
            return {success: false}
        }
    },
    async update(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/author', {
                    method: 'POST',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'author': data.author, 'id': data.id}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }} else {
            return {success: false}
        }
    },
    async insert(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/author', {
                    method: 'PUT',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'author': data.author}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }} else {
            return {success: false}
        }
    },
    async delete(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/author', {
                    method: 'DELETE',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'id': data.id}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }} else {
            return {success: false}
        }
    }
};

export const section = {
    async getList() {
        if (application.isInitialized === true) {
            try {
                let response = {};
                let res = await fetch(req.baseUrl() + '/sections', {
                    method: 'GET',
                    headers: req.headers()
                });
                let sections = await res.json();
                if (res.status === 200) {
                    response.success = true;
                    response.data = sections;
                } else {
                    response = sections;
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    },
    async update(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/section', {
                    method: 'POST',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'section': data.section, 'id': data.id}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    },
    async insert(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/section', {
                    method: 'PUT',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'section': data.section}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    },
    async delete(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/section', {
                    method: 'DELETE',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        { 'id': data.id }
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return { success: false }
            }
        } else {
            return { success: false }
        }
    }
};

export const note = {
    async getList() {
        if (application.isInitialized === true) {
            try {
                let response = {};
                let res = await fetch(req.baseUrl() + '/notes', {
                    method: 'GET',
                    headers: req.headers()
                });
                let notes = await res.json();
                if (res.status === 200) {
                    response.success = true;
                    response.data = notes;
                } else {
                    response = notes;
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    },
    async update(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/note', {
                    method: 'POST',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'note': data.note, 'id': data.id}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    },
    async insert(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/note', {
                    method: 'PUT',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'note': data.note}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    },
    async delete(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/note', {
                    method: 'DELETE',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'id': data.id}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return {success: false}
        }
    }
};

export const book = {
    async getList() {
        if (application.isInitialized === true) {
            try {
                let response = {};
                let res = await fetch(req.baseUrl() + '/books', {
                    method: 'GET',
                    headers: req.headers()
                });
                let books = await res.json();
                if (res.status === 200) {
                    response.success = true;
                    response.data = books;
                } else {
                    response = books;
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return { success: false }
        }
    },
    async update(data) {
        if (application.isInitialized === true) {
            let noteId = null;
            if (data.note !== null && data.note !== undefined) {
                let noteReq = await note.insert(data);
                noteId = noteReq.id;
            }
            let sectionReq = await section.insert(data);
            let sectionId = sectionReq.id;
            let authorReq = await author.insert(data);
            let authorId = authorReq.id;
            try {
                let res = await fetch(req.baseUrl() + '/book', {
                    method: 'POST',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {
                            'id': data.id,
                            'title': data.title,
                            'author': authorId,
                            'section': sectionId,
                            'rank': data.rank,
                            'date': data.date,
                            'format': data.format,
                            'note': noteId !== null ? noteId : ''
                        }
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch (err) {
                return {success: false}
            }
        } else {
            return { success: false }
        }
    },
    async insert(data) {
        if (application.isInitialized === true) {
            let noteId = null;
            if (data.note !== null && data.note !== undefined) {
                let noteReq = await note.insert(data);
                noteId = noteReq.id;
            }
            let sectionReq = await section.insert(data);
            let sectionId = sectionReq.id;
            let authorReq = await author.insert(data);
            let authorId = authorReq.id;
            try {
                let res = await fetch(req.baseUrl() + '/book', {
                    method: 'PUT',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {
                            'title': data.title,
                            'author': authorId,
                            'section': sectionId,
                            'rank': data.rank,
                            'date': data.date,
                            'format': data.format,
                            'note': noteId !== null ? noteId : ''
                        }
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return { success: false }
        }
    },
    async delete(data) {
        if (application.isInitialized === true) {
            try {
                let res = await fetch(req.baseUrl() + '/book', {
                    method: 'DELETE',
                    headers: req.headers(),
                    body: new URLSearchParams(
                        {'id': data.id}
                    )
                });
                let response = await res.json();
                if (res.status === 200) {
                    response.success = true;
                } else {
                    response.success = false;
                }
                return response;
            } catch {
                return {success: false}
            }
        } else {
            return { success: false }
        }
    }
};

export const application = {
    isInitialized: false,
    clientVersion: '2.1.0',
    serverVersion: null,
    async init() {
        try {
            let res = await fetch(req.baseUrl() + '/init', {
                method: 'GET',
                headers: req.headers()
            });
            
            let response = await res.json();
            if (res.status === 200) {
                application.isInitialized = true;
                application.serverVersion = response.version;
                response.success = true;
            } else {
                response.success = false;
            }
            return response;
        } catch {
            return { success: false }
        }
    }
};
