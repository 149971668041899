export const SERVER = {
	ADDRESS: process.env.NODE_ENV === 'production' ? 'https://api.bookstack.extnet.ovh' : 'http://localhost',
	PORT: process.env.NODE_ENV === 'production' ? '' : ':6000',
	API_ENDPOINT: '/api',
	API_VERSION: '/v2'
};

export const EVENTS = {
	UPDATE_AUTHOR_LIST: 'updateAuthorList',
	UPDATE_NOTE_LIST: 'updateNoteList',
	UPDATE_SECTION_LIST: 'updateSectionList',
	UPDATE_BOOK_LIST: 'updateBookList',
	UPDATE_ACCOUNT_KEY: 'updateAccountKey'
};

export const OPERATIONS = {
	INSERT: 'insert',
	UPDATE: 'update'
};