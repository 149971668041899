<template>
	<Table
		:fields="fields"
		:items="items"
		buttonCta="Nuovo Autore"
		:buttonCb="add"
		:selectCb="edit"
		:isPaginated="true"
		caller="author"
	/>
</template>

<script>
import { author } from "@/services";
import { nMutations } from "@/store";
import { EVENTS, OPERATIONS } from "@/constants";
import Table from "@/components/Common/Table.vue";
export default {
	name: "AuthorTable",
	components: { Table },
	data() {
		return {
			fields: [{ key: "author", label: "AUTORI", sortable: true }],
			items: [],
		};
	},
	created() {
		this.registerListener();
		this.init();
	},
	beforeDestroy() {
		this.unregisterListener();
	},
	methods: {
		registerListener() {
			window.addEventListener(EVENTS.UPDATE_AUTHOR_LIST, this.init);
		},
		unregisterListener() {
			window.removeEventListener(EVENTS.UPDATE_AUTHOR_LIST, this.init);
		},
		init() {
			author.getList().then((result) => {
				this.items = result.data;
			});
		},
		add() {
			let data = {
				open: true,
				author: null,
				operation: OPERATIONS.INSERT,
			};
			nMutations.setAuthorManage(data);
		},
		edit(author) {
			let data = {
				open: true,
				author: {
					name: author.author,
					id: author.id,
				},
				operation: OPERATIONS.UPDATE,
			};
			nMutations.setAuthorManage(data);
		},
	},
};
</script>

