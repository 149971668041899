<template>
	<div class="statistics-main-container">
		<div class="statistic-container">
			<div class="statistic-container-top">
				<div class="statistic-icon-container">
					<b-icon-bookmark-heart
						class="statistic-icon"
					></b-icon-bookmark-heart>
				</div>
				<div class="statistic-subtitle-small">Il più letto</div>
			</div>
			<div class="statistic-container-bottom">
				<div class="statistic-container-left">
					<div class="statistic-title">
						{{ data.authorMostRead.books }}
					</div>
				</div>
				<div class="statistic-container-right">
					<div class="statistic-subtitle">
						{{ data.authorMostRead.author }}
					</div>
				</div>
			</div>
		</div>

		<div class="statistic-container">
			<div class="statistic-container-top">
				<div class="statistic-icon-container">
					<b-icon-bookmark-star
						class="statistic-icon"
					></b-icon-bookmark-star>
				</div>
				<div class="statistic-subtitle-small">Il più votato</div>
			</div>
			<div class="statistic-container-bottom">
				<div class="statistic-container-left">
					<div class="statistic-title">
						{{ data.authorMostVoted.rank }}
					</div>
				</div>
				<div class="statistic-container-right">
					<div class="statistic-subtitle">
						{{ data.authorMostVoted.author }}
					</div>
				</div>
			</div>
		</div>

		<div class="statistic-container">
			<div class="statistic-container-top">
				<div class="statistic-icon-container">
					<b-icon-bookmark-check
						class="statistic-icon"
					></b-icon-bookmark-check>
				</div>
				<div class="statistic-subtitle-small">Libri totali</div>
			</div>
			<div class="statistic-container-bottom">
				<div class="statistic-title">{{ data.totalBooks }}</div>
			</div>
		</div>

		<div class="statistic-container">
			<div class="statistic-container-top">
				<div class="statistic-icon-container">
					<b-icon-bookmark-plus
						class="statistic-icon"
					></b-icon-bookmark-plus>
				</div>
				<div class="statistic-subtitle-small">Libri nel {{data.newYear}}</div>
			</div>
			<div class="statistic-container-bottom">
				<div class="statistic-title">{{ data.newBooks }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DashboardStatistics",
	props: {
		data: Object,
	},
};
</script>


<style scoped>
.statistics-main-container {
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
	float: left;
	display: block;
}
.statistic-container {
	width: calc(50% - 5px);
	height: 140px;
	float: left;
	background: #161b22;
	border-radius: 10px;
	color: white;
	margin-bottom: 10px;
}
.statistic-container:nth-child(odd) {
	margin-right: 10px;
}
.statistic-container-left {
	width: 50%;
	float: left;
}
.statistic-container-right {
	width: 50%;
	float: right;
}
.statistic-container-bottom {
	width: 100%;
	float: left;
	height: 90px;
}
.statistic-container-top {
	width: 100%;
	float: left;
	height: 50px;
}
.statistic-subtitle {
	font-weight: bold;
	font-size: 18px;
	height: 90px;
	text-align: right;
	padding-right: 18px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	justify-content: right;
}
.statistic-subtitle-small {
	font-weight: bold;
	font-size: 15px;
	text-align: left;
	margin-left: 17px;
	font-size: 16px;
	margin-top: 7px;
	color: rgba(255, 255, 255, 0.7);
}
.statistic-title {
	font-weight: bold;
	font-size: 40px;
	line-height: 90px;
	height: 90px;
	text-align: center;
}
.statistic-icon {
	width: 25px;
	height: 25px;
	margin-top: 12.5px;
	margin-right: 18px;
	margin-left: 10px;
	float: right;
}

@media screen and (min-width: 1024px) {
	.statistics-main-container {
		width: 240px;
		height: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		float: left;
		display: flex;
	}
	.statistic-container {
		width: 240px;
		height: 140px;
	}
	.statistic-subtitle {
		font-size: 20px;
	}
	.statistic-subtitle-small {
		font-size: 20px;
	}
	.statistic-title {
		font-size: 60px;
	}
}
</style>