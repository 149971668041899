<template>
	<div id="app">
		<Menu />
		<MainPage />
	</div>
</template>

<script>
import { pStore, pGetters } from "@/store";
import { application } from "@/services";
import { EVENTS } from "@/constants";
import BaseNavigation from "@/components/Common/BaseNavigation.vue";
import Menu from "@/components/Menu.vue";
import MainPage from "@/components/MainPage.vue";
export default {
	name: "App",
	mixins: [BaseNavigation],
	components: {
		Menu,
		MainPage,
	},
	created() {
		this.init();
		window.addEventListener(EVENTS.UPDATE_ACCOUNT_KEY, this.init);
	},
	methods: {
		init() {
			pStore.create();
			if (
				pGetters.getUserId() === null ||
				pGetters.getUserId() === undefined ||
				pGetters.getUserId().length === 0 ||
				pGetters.getApiKey() === null ||
				pGetters.getApiKey() === undefined ||
				pGetters.getApiKey().length === 0
			) {
				this.setApplicationAuthOpen();
			} else {
				application.init().then((result) => {
					if (result.success === true) {
						this.setDashboardOpen();
					} else {
						this.setApplicationAuthOpen();
					}
				});
			}
		},
	},
};
</script>

<style>
html {
	height: 100vh;
}
html,
body {
	margin: 0;
	padding: 0;
	background-color: #0d1117;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
select {
	-webkit-appearance: none;
}
.full-height {
	height: 100%;
}
.page-main {
	width: calc(100% - 20px);
	height: calc(100vh - 85px);
	margin-left: 10px;
	margin-top: 10px;
}
@media screen and (min-width: 1024px) {
	.page-main {
		width: calc(100% - 50px);
		height: calc(100vh - 50px);
		margin-left: 25px;
		margin-top: 25px;
	}
}
input::-webkit-calendar-picker-indicator {
	display: block;
	opacity: 1;
	color: white;
}
</style>
