<template>
	<main>
		<div class="page-main author-main">
			<AuthorTable />
		</div>
		<AuthorManage v-if="isAuthorManageOpen() === true" />
	</main>
</template>

<script>
import { nStore } from "@/store";
import AuthorTable from "@/components/Author/AuthorTable.vue";
import AuthorManage from "@/components/Author/AuthorManage.vue";
export default {
	name: "AuthorMain",
	components: {
		AuthorTable,
		AuthorManage,
	},
	methods: {
		isAuthorManageOpen() {
			return nStore.isAuthorManageOpen;
		},
	},
};
</script>
