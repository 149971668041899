<template>
	<main>
		<div class="page-main section-main">
			<SectionTable />
		</div>
		<SectionManage v-if="isSectionManageOpen() === true" />
	</main>
</template>

<script>
import { nStore } from "@/store";
import SectionManage from "@/components/Section/SectionManage.vue";
import SectionTable from "@/components/Section/SectionTable.vue";
export default {
	name: "SectionMain",
	components: {
		SectionTable,
		SectionManage,
	},
	methods: {
		isSectionManageOpen() {
			return nStore.isSectionManageOpen;
		},
	},
};
</script>
